import React, {useEffect, useMemo, useRef, useState} from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { GpxWaypoints, Track } from "../../types/models";
import NeurunApi from "../../api/neurun";
import WaterIcon from "../../assets/img/water-marker.svg";
import StartIcon from "../../assets/img/start-marker.svg";
import FinishIcon from "../../assets/img/finish-icon.svg";
import StartFinishIcon from "../../assets/img/start-finish-icon.svg";
import DownloadIcon from "../../assets/img/download-icon.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid } from "@mui/material";
import { convertToFileName } from "../../helpers/convertToFilename";

type GoogleMaps = typeof google;

interface LatLng {
  lat: number;
  lng: number;
}

let prevUnit = ''

interface CustomMapProps {
  startPoint: LatLng;
  endPoint: LatLng;
  path: LatLng[];
  waypoints: GpxWaypoints[];
  onUpdatePath: (updatedPath: LatLng[]) => void;
  saveGpx: boolean;
  gpxTracks: Track[];
  gpxGuid: string;
  setSaveGpx: (saveGpx: boolean) => void;
  setGpxUpdated: (gpxUpdated: boolean) => void;
  raceUnit: string;
  onUpdateGpxComplete: () => void;
  raceName: string,
  showDownloadButton: boolean
}

const CustomMap: React.FC<CustomMapProps> = ({
                                               startPoint,
                                               endPoint,
                                               path,
                                               waypoints,
                                               onUpdatePath,
                                               gpxTracks,
                                               saveGpx,
                                               gpxGuid,
                                               setSaveGpx,
                                               setGpxUpdated,
                                               raceUnit,
                                               onUpdateGpxComplete,
                                               raceName,
                                               showDownloadButton
                                             }) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const lineRef = useRef<google.maps.Polyline | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [isPlacingAidStation, setIsPlacingAidStation] = useState(false);
  const [updatedWaypoints, setUpdatedWaypoints] = useState<GpxWaypoints[]>([]);
  const [initialWaypointsData, setInitialWaypointsData] = useState<GpxWaypoints[]>([]);
  const [shouldUpdatedGpx, setShouldUpdateGpx] = useState(false);
  const updatedWaypointsRef = useRef<GpxWaypoints[]>([]);

  const isUnitsUpdated = useMemo(() => {
    if (!prevUnit) return false;
    return prevUnit !== raceUnit;
  },[raceUnit])

  useEffect(() => {
    if (saveGpx) {
      (async () => {
        try {
          await updateGpx();
          setSaveGpx(false);
          setGpxUpdated(true);
          onUpdateGpxComplete();
        } catch (err) {
          console.error('Failed to update GPX', err);
          throw err;
        }
      })();
    }
  }, [saveGpx]);

  // useEffect(() => {
  //   if (saveGpx) {
  //     console.log('HERe')
  //     const isEqual = deepCompare(initialWaypointsData, updatedWaypoints)
  //     setShouldUpdateGpx(isEqual)
  //     console.log('IS EQUAL', isEqual)
  //     if (isEqual) {
  //       setSaveGpx(false);
  //       setGpxUpdated(true);
  //       onUpdateGpxComplete();
  //     }
  //   }
  // }, [saveGpx, initialWaypointsData, updatedWaypoints]);

  const updateGpx = async () => {
    const payload = {
      waypoints: updatedWaypoints,
      tracks: gpxTracks,
    };

    try {
      await NeurunApi.updateGpx(payload, gpxGuid);
    } catch (err) {
      // @ts-ignore
      showErrorMessage(err?.response?.data?.message || 'An error occurred while updating GPX');
      throw err;
    }
  };

  const showErrorMessage = (message: string) => {
    toast.error(message, {
      hideProgressBar: true,
    })
  }

  useEffect(() => {
    if (map) {
      map.addListener('click', (event: google.maps.MapMouseEvent) => {
        handleMapClick(event, updatedWaypoints);
      });
    }
  }, [map, isPlacingAidStation]);

  useEffect(() => {
    if (map && lineRef.current && isUnitsUpdated) {
      const filteredWaypoints = filterOutDistanceMarkers(updatedWaypoints).map(i => ({ lat: i.geometry.coordinates[0].lat, lng: i.geometry.coordinates[0].lon }));
      placeKilometerMarkers(google, map, onUpdatePath, raceUnit, null, updatedWaypoints);
    }
  }, [raceUnit, isUnitsUpdated]);

  useEffect(() => {
    updatedWaypointsRef.current = waypoints;
  }, [waypoints]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY',
      libraries: ['geometry'],
    });

    loader.load().then((google: GoogleMaps) => {
      const mapOptions: google.maps.MapOptions = {
        center: new google.maps.LatLng(startPoint.lat, startPoint.lng),
        zoom: 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };

      const data = updatedWaypointsRef.current.length ? updatedWaypointsRef.current : waypoints;

      const initialWaypoints = [...data];
      setInitialWaypointsData(initialWaypoints);
      setUpdatedWaypoints(initialWaypoints);

      const startWaypointIndex = data.findIndex(i => i.properties.name === 'Start');

      const startPosition = {
        lat: startWaypointIndex !== -1 ? data[startWaypointIndex]?.geometry.coordinates[0].lat : startPoint.lat,
        lng: startWaypointIndex !== -1 ? data[startWaypointIndex]?.geometry.coordinates[0].lon : startPoint.lng,
      };

      // const startPosition = { lat: startPoint.lat, lng: startPoint.lng }

      const finishWaypointIndex = data.findIndex(i => i.properties.name === 'Finish');

      const finishPosition = {
        lat: finishWaypointIndex !== -1 ? data[finishWaypointIndex]?.geometry.coordinates[0].lat : path.slice(-1)[0].lat,
        lng: finishWaypointIndex !== -1 ? data[finishWaypointIndex]?.geometry.coordinates[0].lon : path.slice(-1)[0].lng,
      };

      const isStartFinishInOnePlace = finishPosition.lat === startPosition.lat && finishPosition.lng && startPosition.lng;

      const mapInstance = new google.maps.Map(mapRef.current as HTMLDivElement, mapOptions);
      setMap(mapInstance);

      const lineCoordinates = path.map((point) => new google.maps.LatLng(point.lat, point.lng));


      mapInstance.setCenter(new google.maps.LatLng(startPoint.lat, startPoint.lng));
      const line = new google.maps.Polyline({
        path: lineCoordinates,
        strokeColor: '#6271FF',
        strokeOpacity: 2.0,
        strokeWeight: 5,
        map: mapInstance,
      });

      lineRef.current = line;

      const aidStationIcon = {
        url: WaterIcon,
        scaledSize: new google.maps.Size(32, 32),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      const startIcon = {
        url: isStartFinishInOnePlace ? StartFinishIcon : StartIcon,
        scaledSize: new google.maps.Size(52, 52),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      const finishIcon = {
        url: FinishIcon,
        scaledSize: new google.maps.Size(52, 52),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      const startMarker = new google.maps.Marker({
        position: new google.maps.LatLng(startPosition.lat, startPosition.lng),
        map: mapInstance,
        icon: startIcon,
        draggable: true,
      });

      const startData: GpxWaypoints = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [{
            lon: startPoint.lng,
            lat: startPoint.lat,
            ele: 0
          }]
        },
        properties: {
          desc: "Start Point",
          name: "Start",
          sym: "Waypoint",
          type: "Start"
        }
      };

      if (startWaypointIndex !== -1) {
        data[startWaypointIndex] = startData;
        setUpdatedWaypoints(data);
      } else {
        setUpdatedWaypoints((prev) => {
          return [...prev, startData]
        });
      }

      startMarker.addListener('dragend', (event: any) => {
        const newPosition = event.latLng;
        const closestPoint = getClosestPointOnRoute(line, newPosition);
        startMarker.setPosition(closestPoint);

        const updatedPath = [{ lat: closestPoint.lat(), lng: closestPoint.lng() }, ...path.slice(1)];
        onUpdatePath(updatedPath);

        const startData: GpxWaypoints = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [{
              lon: closestPoint.lng(),
              lat: closestPoint.lat(),
              ele: 0
            }]
          },
          properties: {
            desc: "Start Point",
            name: "Start",
            sym: "Waypoint",
            type: "Start"
          }
        };

        // const startWaypointIndex = data.findIndex(i => i.properties.name === 'Start');

        if (startWaypointIndex !== -1) {
          data[startWaypointIndex] = startData;
          setUpdatedWaypoints(data);
        } else {
          setUpdatedWaypoints((prev) => [...prev, startData]);
        }
      });

      if (!isStartFinishInOnePlace) {
        const finishMarker = new google.maps.Marker({
          position: new google.maps.LatLng(finishPosition?.lat, finishPosition?.lng),
          map: mapInstance,
          icon: finishIcon,
          draggable: true,
        });

        const finishData: GpxWaypoints = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [{
              lon: finishPosition?.lng,
              lat: finishPosition?.lat,
              ele: 0
            }]
          },
          properties: {
            desc: "Finish Point",
            name: "Finish",
            sym: "Waypoint",
            type: "Finish"
          }
        };

        if (finishWaypointIndex !== -1) {
          data[finishWaypointIndex] = finishData;
          setUpdatedWaypoints(data);
        } else {
          setUpdatedWaypoints((prev) => [...prev, finishData]);
        }

        finishMarker.addListener('dragend', (event: any) => {
          const newPosition = event.latLng;
          const closestPoint = getClosestPointOnRoute(line, newPosition);
          finishMarker.setPosition(closestPoint);

          const updatedPath = [...path.slice(0, -1), { lat: closestPoint.lat(), lng: closestPoint.lng() }];
          onUpdatePath(updatedPath);

          const finishData: GpxWaypoints = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [{
                lon: closestPoint.lng(),
                lat: closestPoint.lat(),
                ele: 0
              }]
            },
            properties: {
              desc: "Finish Point",
              name: "Finish",
              sym: "Waypoint",
              type: "Finish"
            }
          };

          const finishWaypointIndex = data.findIndex(i => i.properties.name === 'Finish');

          if (finishWaypointIndex !== -1) {
            data[finishWaypointIndex] = finishData;
            setUpdatedWaypoints(data);
          } else {
            setUpdatedWaypoints((prev) => [...prev, finishData]);
          }

        });
      }

      if (waypoints.some(i => i.properties.type === 'DistanceMarker') && !isUnitsUpdated) {
        const updatedPath = waypoints.filter(i => i.properties.type === 'DistanceMarker').map(i => ({ lat: i.geometry.coordinates[0].lat, lng: i.geometry.coordinates[0].lon }))
        placeKilometerMarkers(google, mapInstance, onUpdatePath, raceUnit, updatedPath, data);
      }

      initialWaypoints.forEach((waypoint, index) => {
        if (waypoint.properties.type !== 'DistanceMarker' && waypoint.properties.type !== 'Start' && waypoint.properties.type !== 'Finish' && waypoint?.properties?.type === 'AidStation') {
          const coordinates = waypoint.geometry.coordinates[0];
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(coordinates.lat, coordinates.lon),
            map: mapInstance,
            icon: aidStationIcon,
            draggable: true,
          });

          marker.addListener('click', () => {
            marker.setMap(null);
            setUpdatedWaypoints((prev) => {
              return prev.filter(waypoint => waypoint.geometry.coordinates[0].lat !== coordinates.lat && waypoint.geometry.coordinates[0].lon !== coordinates.lon)
            });
          });

          google.maps.event.addListener(marker, 'dragend', (event: any) => {
            const newPosition = event.latLng;
            const closestPoint = getClosestPointOnRoute(line, newPosition);
            marker.setPosition(closestPoint);

            const updatedWaypoint = {
              ...waypoint,
              geometry: {
                ...waypoint.geometry,
                coordinates: [{
                  lon: closestPoint.lng(),
                  lat: closestPoint.lat(),
                  ele: coordinates.ele,
                }]
              }
            };

            const newUpdatedWaypoints = [...initialWaypoints];
            newUpdatedWaypoints[index] = updatedWaypoint;
            setUpdatedWaypoints(newUpdatedWaypoints);
          });
        }
      });

      if (!waypoints || !waypoints.some(i => i.properties.type === 'DistanceMarker') || isUnitsUpdated) {
        placeKilometerMarkers(google, mapInstance, onUpdatePath, raceUnit, null, data);
      }
    });
  }, [startPoint, endPoint, path, waypoints, onUpdatePath, raceUnit, updatedWaypointsRef.current]);

  const createKmMarkerIcon = (km: number): google.maps.Icon => {
    const canvas = document.createElement('canvas');
    canvas.width = 40;
    canvas.height = 40;
    const context = canvas.getContext('2d');

    if (context) {
      // Draw circle
      context.beginPath();
      context.arc(20, 20, 18, 0, 2 * Math.PI, false);
      context.fillStyle = '#4C57BC';
      context.fill();
      context.lineWidth = 3;
      context.strokeStyle = '#FFFFFF';
      context.stroke();

      // Draw text
      context.fillStyle = '#FFFFFF';
      context.font = '16px Arial';
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(km.toString(), 20, 20);
    }

    return {
      url: canvas.toDataURL(),
      scaledSize: new google.maps.Size(40, 40),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(20, 20),
    };
  };

  const placeKilometerMarkers = (
    google: GoogleMaps,
    map: google.maps.Map,
    onUpdatePath: (updatedPath: LatLng[]) => void,
    unit: string,
    updatedPath: LatLng[] | null,
    waypointsData: GpxWaypoints[]
  ) => {
    const distancePerKm = unit === 'metric' ? 1000 : 1609.34;

    const sphericalLib = google.maps.geometry.spherical;
    let kmCount = 1;
    let distanceTraveled = 0;

    const filteredWaypoints = filterOutDistanceMarkers(waypointsData);
    const filteredAidStations = filterOutAidStations(waypointsData);

    if (updatedPath) {
      placeMarkersForUpdatedPath(google, map, updatedPath, distancePerKm, filteredWaypoints, kmCount);
    } else {
      placeMarkersForOriginalPath(google, map, path, distancePerKm, filteredWaypoints, kmCount, distanceTraveled, sphericalLib);
    }
    // updateWaypointsWithNewMarkers(updatedPath ? filteredWaypoints : []);
    if (isUnitsUpdated) {
      setUpdatedWaypoints(filteredWaypoints);
    } else {
      updateWaypointsWithNewMarkers(filteredWaypoints);
    }
  };

  const filterOutDistanceMarkers = (waypoints: GpxWaypoints[]) => {
    return waypoints.filter((waypoint) => waypoint.properties.type !== "DistanceMarker" && waypoint.properties.type !== 'Start' && waypoint.properties.type !== 'Finish');
  };

  const filterOutAidStations = (waypoints: GpxWaypoints[]) => {
    return waypoints.filter((waypoint) => waypoint.properties.type === "AidStation");
  };

  const placeMarkersForUpdatedPath = (
    google: GoogleMaps,
    map: google.maps.Map,
    updatedPath: LatLng[],
    distancePerKm: number,
    filteredWaypoints: GpxWaypoints[],
    kmCount: number
  ) => {
    updatedPath.forEach((position, index) => {
      const posLatLng = new google.maps.LatLng(position.lat, position.lng);
      const marker = createMarker(google, map, posLatLng, kmCount);

      const newKmWaypoint = createWaypoint(kmCount, posLatLng);
      filteredWaypoints.push(newKmWaypoint);

      addDragEndListenerToMarker(google, marker, kmCount);
      kmCount++;
    });
  };

  const placeMarkersForOriginalPath = (
    google: GoogleMaps,
    map: google.maps.Map,
    path: LatLng[],
    distancePerKm: number,
    filteredWaypoints: GpxWaypoints[],
    kmCount: number,
    distanceTraveled: number,
    sphericalLib: any
  ) => {
    for (let i = 1; i < path.length; i++) {
      const segmentDistance = sphericalLib.computeDistanceBetween(
        new google.maps.LatLng(path[i - 1].lat, path[i - 1].lng),
        new google.maps.LatLng(path[i].lat, path[i].lng)
      );
      distanceTraveled += segmentDistance;

      if (distanceTraveled >= distancePerKm) {
        const posLatLng = new google.maps.LatLng(path[i].lat, path[i].lng);
        const marker = createMarker(google, map, posLatLng, kmCount);

        const newKmWaypoint = createWaypoint(kmCount, posLatLng);
        filteredWaypoints.push(newKmWaypoint);

        addDragEndListenerToMarker(google, marker, kmCount);

        kmCount++;
        distanceTraveled -= distancePerKm;
      }
    }
  };

  const createMarker = (
    google: GoogleMaps,
    map: google.maps.Map,
    position: google.maps.LatLng,
    kmCount: number
  ) => {
    return new google.maps.Marker({
      position: position,
      map: map,
      icon: createKmMarkerIcon(kmCount),
      draggable: true,
      title: `KM ${kmCount}`,
    });
  };

  const createWaypoint = (kmCount: number, position: google.maps.LatLng) => {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          {
            lon: position.lng(),
            lat: position.lat(),
            ele: 0,
          },
        ],
      },
      properties: {
        desc: `Kilometer ${kmCount}`,
        name: `KM ${kmCount}`,
        sym: "Waypoint",
        type: "DistanceMarker",
      },
    };
  };

  const addDragEndListenerToMarker = (
    google: GoogleMaps,
    marker: google.maps.Marker,
    kmCount: number
  ) => {
    google.maps.event.addListener(marker, "dragend", (event: any) => {
      const newPosition = event.latLng;
      const closestPoint = getClosestPointOnRoute(lineRef.current!, newPosition);
      marker.setPosition(closestPoint);

      setUpdatedWaypoints((prev) => {
        const updatedWaypoints = [...prev];
        // @ts-ignore
        const distanceMarkerIndex = updatedWaypoints.findIndex((i) => i.properties.name === `${marker?.title}`);

        const updatedWaypoint = updatedWaypoints[distanceMarkerIndex];
        const coordinates = updatedWaypoint?.geometry?.coordinates[0];

        const updatedData = {
          ...updatedWaypoint,
          geometry: {
            ...updatedWaypoint?.geometry,
            coordinates: [
              {
                lon: closestPoint.lng(),
                lat: closestPoint.lat(),
                ele: coordinates?.ele,
              },
            ],
          },
        };

        updatedWaypoints[distanceMarkerIndex] = updatedData;
        return updatedWaypoints;
      });
    });
  };

  const updateWaypointsWithNewMarkers = (filteredWaypoints: GpxWaypoints[]) => {
    setUpdatedWaypoints((prev) => {
      const updatedWaypoints = [...prev];

      filteredWaypoints.forEach((filteredWaypoint) => {
        const existingIndex = updatedWaypoints.findIndex(
          (wp) => wp.properties.name === filteredWaypoint.properties.name
        );

        if (existingIndex !== -1) {
          updatedWaypoints[existingIndex].geometry.coordinates = filteredWaypoint.geometry.coordinates;
        } else {
          updatedWaypoints.push(filteredWaypoint);
        }
      });

      return updatedWaypoints;
    });
  };


  const getClosestPointOnRoute = (
    line: google.maps.Polyline,
    point: google.maps.LatLng
  ): google.maps.LatLng => {
    const path = line.getPath().getArray();
    let closestPoint = path[0];
    let minDistance = google.maps.geometry.spherical.computeDistanceBetween(point, closestPoint);

    for (let i = 1; i < path.length; i++) {
      const segmentStart = path[i - 1];
      const segmentEnd = path[i];

      const closestSegmentPoint = getClosestPointOnSegment(segmentStart, segmentEnd, point);
      const distance = google.maps.geometry.spherical.computeDistanceBetween(point, closestSegmentPoint);

      if (distance < minDistance) {
        minDistance = distance;
        closestPoint = closestSegmentPoint;
      }
    }

    return closestPoint;
  };

  const getClosestPointOnSegment = (
    start: google.maps.LatLng,
    end: google.maps.LatLng,
    point: google.maps.LatLng
  ): google.maps.LatLng => {
    const latLngToVector = (latLng: google.maps.LatLng) => ({
      x: latLng.lng(),
      y: latLng.lat(),
    });

    const vectorToLatLng = (vector: { x: number; y: number }) => new google.maps.LatLng(vector.y, vector.x);

    const startVector = latLngToVector(start);
    const endVector = latLngToVector(end);
    const pointVector = latLngToVector(point);

    const segmentVector = { x: endVector.x - startVector.x, y: endVector.y - startVector.y };
    const pointStartVector = { x: pointVector.x - startVector.x, y: pointVector.y - startVector.y };

    const segmentLengthSquared = segmentVector.x * segmentVector.x + segmentVector.y * segmentVector.y;
    const t = Math.max(0, Math.min(1, (pointStartVector.x * segmentVector.x + pointStartVector.y * segmentVector.y) / segmentLengthSquared));

    return vectorToLatLng({
      x: startVector.x + t * segmentVector.x,
      y: startVector.y + t * segmentVector.y,
    });
  };

  const handleMapClick = (event: google.maps.MapMouseEvent, data: GpxWaypoints[]) => {
    if (isPlacingAidStation && event.latLng && lineRef.current) {
      const aidStationIcon = {
        url: WaterIcon,
        scaledSize: new google.maps.Size(32, 32),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      const closestPoint = getClosestPointOnRoute(lineRef.current, event.latLng);

      const marker = new google.maps.Marker({
        position: closestPoint,
        map: map!,
        icon: aidStationIcon,
      });

      marker.addListener('click', () => {
        marker.setMap(null);
        setUpdatedWaypoints((prev) => {
          const newAidStation = createAidStation(closestPoint, prev);
          return prev.filter(waypoint => waypoint.geometry.coordinates[0].lat !== newAidStation.geometry.coordinates[0].lat && waypoint.geometry.coordinates[0].lon !== newAidStation.geometry.coordinates[0].lon);
        });
      });

      setUpdatedWaypoints((prev) => {
        const newAidStation = createAidStation(closestPoint, prev);
        const newWaypoints = [...prev, newAidStation];
        return newWaypoints;
      });
    }
  };

  const createAidStation = (coordinates: google.maps.LatLng, waypointsData: GpxWaypoints[]) => {
    const newAidStationName = `Aid Station ${waypointsData.filter(waypoint => waypoint.properties.type === 'AidStation').length + 1}`;

    const newAidStation: GpxWaypoints = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          {
            lon: coordinates.lng(),
            lat: coordinates.lat(),
            ele: 0,
          },
        ],
      },
      properties: {
        desc: "Custom Aid Station",
        name: newAidStationName, // Set the dynamic name
        sym: "Waypoint",
        type: "AidStation",
      },
    };

    return newAidStation;
  }

  const handleAidStationButtonClick = () => {
    setIsPlacingAidStation(!isPlacingAidStation);
  };

  const handleDownloadGpxFile = async () => {
    try {
      const data = await NeurunApi.downloadGpxFile(gpxGuid);
      const fileContent = await data.text();

      const blob = new Blob([fileContent], { type: 'text/csv' });

      const url = window.URL.createObjectURL(blob);
      if (url) {
        const a = document.createElement('a');
        const fileName = convertToFileName(raceName);
        a.href = url;
        a.download = `${fileName}.gpx`;
        a.click();

        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      showErrorMessage('An error occured while downloading GPX');
      throw err;
    }
  }

  return (
    <>
      <div>
        <Grid container spacing={2}>
          <Grid item md={showDownloadButton ? 8 : 12} xs={12}>
            <button type="button" onClick={handleAidStationButtonClick}
                    className='btn'>{isPlacingAidStation ? 'Please mark the Aid Station on the map' : 'Add Aid Station'}</button>
          </Grid>
          {showDownloadButton ? (
            <Grid item md={4} xs={12}>
              <button
                type="button"
                className='btn'
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '12px' }}
                onClick={handleDownloadGpxFile}
              >
                Download GPX File
                <img src={DownloadIcon} alt='Download' style={{ width: '22px', height: '22px' }} />
              </button>
            </Grid>
          ) : null}
        </Grid>
      </div>
      <div ref={mapRef} style={{height: '500px', width: '100%'}}/>
      <ToastContainer />
    </>
  );
};

export default React.memo(
  CustomMap,
  (prevProps, nextProps) => {
    prevUnit = prevProps.raceUnit
    return (
      prevProps.raceUnit === nextProps.raceUnit && prevProps.saveGpx === nextProps.saveGpx && prevProps.path === nextProps.path
    )
  }
);
