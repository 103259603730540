import React from "react";
import {Box, Autocomplete, TextField, Typography} from "@mui/material";
import {CountryType} from "../../types/models";

interface CustomAutocompleteProps {
  title: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value:  CountryType | string;
  placeholder: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  options: CountryType[];
  required?: boolean
}

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({ title, name, onChange, value, placeholder, onBlur, error, options, required = false }) => {
  return (
    <Box>
      <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={0.5}>
        {title}
        {required ? (
          <span style={styles.requiredField}>*</span>
        ) : null}
      </Typography>
      <Autocomplete
        id={name}
        options={options}
        name={name}
        autoHighlight
        disableClearable
        onBlur={onBlur}
        // @ts-ignore
        value={value}
        // @ts-ignore
        onChange={(event: any, newValue: string | null) => {
          // @ts-ignore
          onChange(newValue);
        }}
        getOptionLabel={(option) => option.label || ''}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <Box
              key={key}
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...optionProps}
            >
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                alt=""
              />
              {option.label}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={styles.inputField}
            variant="outlined"
            fullWidth
            error={!!error}
            placeholder={placeholder}
            helperText={error}
          />
        )}
      />
    </Box>
  );
}

const styles = {
  inputField: {
    '& .MuiOutlinedInput-root': {
      padding: '4px',
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#ccc',
      },
    },
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
  },
  requiredField: {
    color: 'red',
    marginLeft: '8px'
  }
}

export default CustomAutocomplete;
