import React, { useMemo, useState } from 'react';
import { Box, CssBaseline, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import MuiCard from '@mui/material/Card';
import CustomTextField from "../../components/CustomTextField";
import NeurunApi from "../../api/neurun";
import { useNavigate } from 'react-router-dom';

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
      backgroundImage:
        'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const LoginPage = () => {
  const [secretKey, setSecretKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (isValidSecretKey) {
      try {
        setIsLoading(true);
        const { access_token, refresh_token } = await NeurunApi.getNeurunTokens(secretKey);
        localStorage.setItem('token', access_token)
        localStorage.setItem('refresh_token', refresh_token)
        localStorage.setItem('secret_key', secretKey)

        navigate('/my-races');
      } catch (err) {
        setError('invalid key, please try again.')
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isValidSecretKey = useMemo(() => secretKey && secretKey.trim().length, [secretKey]);

  return (
    <Box>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Typography
            fontWeight='600' fontSize='24px' fontFamily='Montserrat' py={0.5}
          >
            Sign in
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <CustomTextField
              title='Secret Key'
              value={secretKey}
              onChange={(event) => setSecretKey(event?.target?.value)}
              name='name'
              required
              error={error}
              placeholder='Enter Secret Key'
            />
            <button disabled={!isValidSecretKey || isLoading} className='submit-btn' onClick={onSubmit}>Submit</button>
          </Box>
        </Card>
      </SignInContainer>
    </Box>
  )
}

export default LoginPage;
