export const validateDateTime = (dateTimeString: string): { valid: boolean, message: string } => {
  const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

  if (!dateTimeRegex.test(dateTimeString)) {
    return {
      valid: false,
      message: 'Invalid format'
    };
  }

  const date = new Date(dateTimeString);

  if (isNaN(date.getTime())) {
    return {
      valid: false,
      message: 'Invalid date or time'
    };
  }

  const now = new Date();
  if (date.getTime() < now.getTime()) {
    return {
      valid: false,
      message: 'The date cannot be in the past'
    };
  }

  return {
    valid: true,
    message: ''
  };
}
