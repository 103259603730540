import React, {useEffect, useState} from 'react';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow, Typography
} from "@mui/material";
import {Race} from "../../types/models";
import {capitalizeFirstLetter} from "../../helpers/capitalizeFirstLetter";
import NeurunApi from "../../api/neurun";
import { useNavigate } from 'react-router-dom';
import LogoutSvgIcon from '../../assets/img/logout-svg-icon.svg';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'distance',
    numeric: true,
    disablePadding: true,
    label: 'Distance',
  },
  {
    id: 'surface_type',
    numeric: false,
    disablePadding: true,
    label: 'Surface type',
  },
  {
    id: 'starting_at',
    numeric: false,
    disablePadding: true,
    label: 'Starting at',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: true,
    label: 'Location',
  },
]

const RaceListPage = () => {
  const [races, setRaces] = useState<Race[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const secretKey = localStorage.getItem('secret_key');
    if (!secretKey) {
      navigate('/login');
      return;
    }
    getRaceList(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const getRaceList = async (page: number, rowsPerPage: number) => {
    setIsLoading(true);
    const skip = page * rowsPerPage; // Calculate the number of items to skip
    const result = await NeurunApi.getProviderRaceList(skip, rowsPerPage);

    setRaces(result.data || []);
    setTotalCount(result.total_count || 0); // Set the total count for pagination
    setIsLoading(false);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(date);
  };

  const onAddNewRaceClick = () => {
    navigate('/race')
  }

  const handleRowClick = (id: string) => {
    navigate(`/race/${id}`)
  }

  return (
    <div>
      <Box sx={{width: '100%', marginTop: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
          <Typography variant='h4' fontWeight='500'>Race list</Typography>
          <button className='btn' style={{ width: '150px', fontSize: '15px', padding: '10px' }} onClick={onAddNewRaceClick}>Add new race</button>
        </div>
        {isLoading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            mt={4}
          >
            <CircularProgress color='info' />
          </Box>
        ) : (
          <div>
            {!races?.length && !isLoading ? (
              <Typography textAlign='center'>There are no races</Typography>
            ) : (
              <Paper sx={{width: '100%', mb: 2}}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size='medium'
                  >
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align='left'
                            padding='normal'
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {races.map((row) => {
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleRowClick(row?.external_id || '')}
                            tabIndex={-1}
                            key={row.guid}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.distance}</TableCell>
                            <TableCell align="left" style={{ minWidth: '110px' }}>{capitalizeFirstLetter(row.surface_type)}</TableCell>
                            <TableCell align="left">{formatDate(row.starting_at)}</TableCell>
                            <TableCell align="left">{row.location}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
          </div>
        )}
      </Box>
    </div>
  )
}

export default RaceListPage;
