import React from 'react';

const AnalyticsPage = () => {
  return (
    <div>
      <h1>Analytics</h1>
      <p>Welcome to the Analytics!</p>
    </div>
  );
}

export default AnalyticsPage
