import React from 'react';

const ContentManagementPage = () => {
  return (
    <div>
      <h1>Content Management</h1>
      <p>Welcome to the Content Management!</p>
    </div>
  );
}

export default ContentManagementPage
