import React from "react";
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {Box, Typography} from "@mui/material";
import {DateTimeValidationError, PickerChangeHandlerContext} from "@mui/x-date-pickers";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

interface CustomDateTimePickerProps {
  title: string;
  name: string;
  onChange: (value: Dayjs | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => void
  value: string;
  onBlur?: () => void;
  error?: string;
  required?: boolean
}

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomDateTimePicker: React.FC<CustomDateTimePickerProps> = ({ title, name, onChange, onBlur, value, error, required = false }) => {
  return (
    <Box width='100%'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={0.5}>
          {title}
          {required ? (
            <span style={styles.requiredField}>*</span>
          ) : null}
        </Typography>
        <DateTimePicker
          name={name}
          ampm={false}
          minDateTime={dayjs(new Date())}
          onChange={onChange}
          onError={(e) => console.log('EE', e)}
          sx={styles.inputField}
          value={dayjs(value).utc()}
          defaultValue={dayjs(new Date())}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
        />
      </LocalizationProvider>
      {error ? (
        <span style={{ ...styles.errorMessage }}>{error}</span>
      ) : ''}
    </Box>
  )
}

const styles = {
  inputField: {
    '&.MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      width: '100%',
      '& fieldset': {
        borderColor: '#ccc',
      },
    },
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
  },
  requiredField: {
    color: 'red',
    marginLeft: '8px'
  },
  errorMessage: {
    color: '#d32f2f',
    fontSize: '0.75rem',
    marginLeft: '14px',
  },
}

export default CustomDateTimePicker;

