import {Box, Paper, Typography} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Logo from "../../assets/img/neurun-logo.svg";
const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/login');
    }
  }, []);

  const navigateToRaceList = () => {
    navigate('/my-races');
  }

    return (
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <img src={Logo} alt='Logo'/>
        <Typography fontSize='22px' mt={5}>Page not found</Typography>
        <Box mt={2}>
          <button type='button' className='btn' style={{ minWidth: '210px', marginTop: '12px', fontSize: '22px' }} onClick={navigateToRaceList}>
            <span>Go to the Race List</span>
          </button>
        </Box>
      </Box>
    )
}

export default NotFound
