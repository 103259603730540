import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import HomePage from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import AuthProvider from "./сomponents/AuthProvider";
import AppLayout from "./сomponents/AppLayout";
import Login from "./Pages/Login";
import RaceListPage from "./Pages/RaceList";
import DashboardPage from "./Pages/Dashboard";
import ContentManagementPage from "./Pages/ContentManagement";
import WidgetCustomizationPage from "./Pages/WidgetCustomization";
import SponsorManagementPage from "./Pages/SponsorManagement";
import AnalyticsPage from "./Pages/AnalyticsDashboard";
import SettingsPage from "./Pages/Settings";

function App() {
  return (
      <AppLayout>
          <AuthProvider>
              <Routes>
                <Route path="/" element={<RaceListPage />} />
                <Route path="/race/njuko/:edition_id" element={<HomePage />} />
                <Route path="/race/:race_id" element={<HomePage />} />
                <Route path="/race" element={<HomePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/my-races" element={<RaceListPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/faq-content-management" element={<ContentManagementPage />} />
                <Route path="/widget-customization" element={<WidgetCustomizationPage />} />
                <Route path="/sponsor-management" element={<SponsorManagementPage />} />
                <Route path="/analytics-dashboard" element={<AnalyticsPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
          </AuthProvider>
      </AppLayout>
  );
}

export default App;
