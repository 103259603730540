import neurunAxios, {neurunAxiosInstance} from "./axiosInstance";
import {GpxFile, UpdateGpxPayload} from "../../types/models";

class NeurunApi {
    static async getNeurunTokens(api_key: string): Promise<{ access_token: string, refresh_token: string }>{
        const result = await neurunAxios.post(`/login`, {
            api_key: api_key || this.getApiKey()
        })

        return result.data
    }

    static async refreshNeurunTokens(refreshToken: string): Promise<{ access_token: string, refresh_token: string }> {
        const result = await neurunAxios.post(`/refresh`, {
            refresh_token: refreshToken
        })

        return result.data
    }

    static async getNjukoToken(editionId: string): Promise<{ token: string }> {
        const result = await neurunAxios.post(`/njuko/token`, {
            edition_id: editionId
        })

        return result.data
    }

    static async getRace(editionId: string) {
        const result = await neurunAxios.get(`race/${editionId}`)

        return result.data
    }

    static async uploadGpx(payload: FormData): Promise<GpxFile> {
        const result = await neurunAxios.post('gpx/upload', payload, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        })

        return result.data
    }

    static async createRace(payload: Record<string, any>) {
        const result = await neurunAxios.post('race', payload, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        })

        return result.data
    }

    static async updateRace(payload: Record<string, any>, guid: string) {
        const result = await neurunAxios.put(`race/${guid}`, payload, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        })

        return result.data
    }

    static async updateGpx(payload: UpdateGpxPayload, gpx_guid: string) {
        const result = await neurunAxios.put(`gpx/${gpx_guid}`, payload);

        return result?.data
    }

    static async getGpxData(race_guid: string): Promise<GpxFile> {
        const result = await neurunAxiosInstance.get(`/race/gpx/${race_guid}`);

        return result?.data
    }

    static getApiKey() {
        const env = process.env.REACT_APP_ENV || 'PROD'
        return process.env[`REACT_APP_${env}_NEURUN_API_KEY`];
    }

    static async downloadGpxFile(gpx_file_guid: string) {
        const result = await neurunAxios.get(`/gpx/download/${gpx_file_guid}`, {
            responseType: 'blob'
        });
        return result?.data;
    }

    static async getProviderRaceList(skip = 0, take = 5) {
        const result = await neurunAxios.get(`/race/list?take=${take}&skip=${skip}`);

        return result?.data;
    }
}

export default NeurunApi
