import React from 'react';
import { useLocation } from "react-router-dom";
import NavigationDrawer from "../../components/NavigationDrawer";

type AppLayoutProps = {
    children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
    const location = useLocation();

    const hideDrawerRoutes = ['/login'];
    const shouldShowDrawer = !hideDrawerRoutes.includes(location.pathname);


    return (
      <div>
          {shouldShowDrawer
            ? <NavigationDrawer>{children}</NavigationDrawer>
            : <div>{children}</div>
          }
      </div>
    );
};

export default AppLayout;
